@font-face {
  font-family: "Officina Serif";
  src: url("fonts/officina-serif-book.woff2") format("woff2");
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
}
